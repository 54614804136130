import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  ANALYTICS_EVENTS,
  ANALYTICS_VIEW_FEATURE_LOCATIONS,
  ANALYTICS_VIEW_FEATURES,
  ANALYTICS_VIEWS
} from 'dpl/constants/analytics';
import { addQueryToUrl } from 'dpl/util/queryString';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import SectionViewTracker from 'dpl/common/components/SectionViewTracker';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { exploreBreedsByState } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import ClickableDiv from 'dpl/common/components/ClickableDiv';
import SmartLink from 'dpl/components/SmartLink';
import TravelRadiusMap from 'dpl/slp/components/TravelRadiusMap';
import HorizontalSlider from 'dpl/components/HorizontalSlider';
import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import { makeSequence } from 'dpl/shared/utils';

import BreederProfileBreedFilter from './BreederProfileBreedFilter';

function ExploreBreedByStateCard({ breedName, state }) {
  const {
    id,
    name,
    coordinates,
    page_url: url,
    puppy_count: puppyCount,
    breeder_count: breederCount
  } = state;

  const buttonCta = puppyCount === 0 ? 'View breeders' : 'View puppies';

  const searchUrl = addQueryToUrl(
    {
      origin_view: ANALYTICS_VIEWS.BREEDER_PROFILE,
      origin_view_feature_location:
        ANALYTICS_VIEW_FEATURE_LOCATIONS.EXPLORE_BREED_BY_STATE_MODULE
    },
    url
  );

  function handleClick() {
    sendRudderstackEvent(ANALYTICS_EVENTS.BUTTON_CLICKED, {
      button_cta: buttonCta,
      view: ANALYTICS_VIEWS.BREEDER_PROFILE,
      view_feature: ANALYTICS_VIEW_FEATURES.VIEW_PUPPIES_BUTTON,
      view_feature_location:
        ANALYTICS_VIEW_FEATURE_LOCATIONS.EXPLORE_BREED_BY_STATE_MODULE
    });
  }

  return (
    <ClickableDiv
      className="w-100"
      onClick={() => {
        handleClick();
        window.location.assign(searchUrl);
      }}
    >
      <div className="ExploreBreedByStateCard flex flex-column bg-white ba b--subtle-gray br3 overflow-hidden">
        <TravelRadiusMap
          id={`state-${id}`}
          className="flex flex-column"
          locationName={name}
          origin={coordinates}
          radius={300}
          showPin={false}
          stateName={name}
        />
        <div className="bb bt b--subtle-gray pv4 ph4 tc">
          <h5 className="font-24 lh-title">{name}</h5>
          <p className="mt2 font-16 secondary">
            {puppyCount === 0
              ? `${breederCount} ${breedName} Certified ${breederCount === 1 ? 'breeder' : 'breeders'}`
              : `${puppyCount} ${breedName} ${puppyCount === 1 ? 'puppy' : 'puppies'} available `}
          </p>
        </div>
        <SmartLink
          to={searchUrl}
          className="font-16 fw-medium stone-700 tc pv4 ph4"
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
        >
          {buttonCta}
        </SmartLink>
      </div>
    </ClickableDiv>
  );
}

ExploreBreedByStateCard.propTypes = {
  breedName: PropTypes.string.isRequired,
  state: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    coordinates: PropTypes.arrayOf(PropTypes.number),
    page_url: PropTypes.string,
    puppy_count: PropTypes.number,
    breeder_count: PropTypes.number
  }).isRequired
};

export default function ExploreBreedByStateModule() {
  const { analyticsPageName, breederProfileId } = useSelector(
    ({ server }) => server
  );

  const [selectedFilterIdx, setSelectedFilterIdx] = useState(0);

  const {
    isLoading: isLoadingBreeds,
    value: {
      meta,
      data: { breeds }
    }
  } = useResourceQuery(exploreBreedsByState(breederProfileId));

  if (!isLoadingBreeds && !meta?.has_puppy_or_breeder_counts) {
    return null;
  }

  function handleBreedClick(idx) {
    setSelectedFilterIdx(idx);
  }

  const selectedBreed = breeds[selectedFilterIdx];

  return (
    <SectionViewTracker
      category="breeder-profile-explore-by-state"
      name={analyticsPageName}
    >
      <div className="ExploreBreedByStateModule bg-gray-100 pv10 pv20-md">
        <div className="container-xxl">
          {(() => {
            if (isLoadingBreeds) {
              return (
                <>
                  <SkeletonBlock
                    className="font-24 md:font-40 lh-title mb8"
                    height="1em"
                    width="12em"
                  />
                  <SkeletonBlock
                    className="font-16 br-pill mb6 mb14-md"
                    height="3em"
                    width="7em"
                  />
                  <div className="dn flex-ns gap-20">
                    {makeSequence(3).map(index => (
                      <SkeletonBlock
                        className="ExploreBreedByStateCard"
                        key={index}
                        height="550px"
                      />
                    ))}
                  </div>
                  <div className="db dn-ns">
                    <SkeletonBlock className="w-100" height="500px" />
                  </div>
                </>
              );
            }

            return (
              <>
                <h3 className="font-24 md:font-40 lh-title mb8">
                  Explore available puppies by state
                </h3>
                <BreederProfileBreedFilter
                  className="mb6 mb14-md"
                  items={breeds.map(b => ({
                    label: b.name,
                    photo_url: b.cover_photo
                  }))}
                  onFilterClick={handleBreedClick}
                  selectedFilterIdx={selectedFilterIdx}
                />
                {selectedBreed && (
                  <HorizontalSlider
                    containerClassName="flex flex-nowrap gap-8 md:gap-20 ph6 ph0-sm"
                    className="nl6 nr6 nl0-sm nr0-sm"
                    iconClassName="secondary bg-white ph2 pv2 br-100 ba b--hairline"
                    iconName="fetch-chevron"
                  >
                    {selectedBreed.states.map((state, idx) => {
                      if (!state.puppy_count && !state.breeder_count) {
                        return null;
                      }

                      return (
                        <div className="flex ws-normal" key={idx}>
                          <ExploreBreedByStateCard
                            breedName={selectedBreed.name}
                            state={state}
                          />
                        </div>
                      );
                    })}
                  </HorizontalSlider>
                )}
              </>
            );
          })()}
        </div>
      </div>
    </SectionViewTracker>
  );
}
