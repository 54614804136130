import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { littersResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';
import {
  LITTER_STATES,
  LITTER_STATE_TITLES
} from 'dpl/shared/constants/shared';
import { pushParams } from 'dpl/actions/query_params';
import { LITTER_ID } from 'dpl/constants/query_params';
import { BreederProfileLitterPropTypes } from 'dpl/shared/utils/customPropTypes';
import LitterParentsVennImages from 'dpl/breeder_dashboard/components/LitterParentsVennImages';
import Button from 'dpl/common/design_system/Button';
import HorizontalSlider from 'dpl/components/HorizontalSlider';
import BreederProfileLittersSkeleton from 'dpl/components/BreederProfile/Show/skeletons/BreederProfileLittersSkeleton';
import useBreederProfileTabs from 'dpl/components/BreederProfile/hooks/useBreederProfileTabs';
import { TAB_KEYS } from 'dpl/components/BreederProfile/utils/constants';

const { BORN } = LITTER_STATES;
const MAX_LITTERS_TO_SHOW = 3;
const SMART_IMAGE_PROPS = {
  width: '56px',
  height: '56px',
  className: 'dib flex-none',
  isBordered: true,
  isOverlapping: true
};

const MOBILE_LAYOUT_TYPES = {
  CAROUSEL: 'carousel',
  STACKED: 'stacked'
};

function getDisplayBirthDate(litter) {
  return `${litter.status === BORN ? 'Born' : 'Due'} ${
    litter.birth_date_month_year
  }`;
}

function getLitterStatus(litter) {
  return `${LITTER_STATE_TITLES[litter.status]} ${
    litter.pluralized_breed_name
  } from ${litter.parent_names}`;
}

function LitterCard({ litter, onLitterClick }) {
  return (
    <div className="BreederProfileLitterCard flex flex-column gap-24 bg-white br4 ba b--subtle-gray overflow-hidden ph4 pv4">
      <LitterParentsVennImages
        litterParents={litter.parents}
        vennImageProps={SMART_IMAGE_PROPS}
      />
      <div>
        <p className="font-serif font-20">{getLitterStatus(litter)}</p>
        <p className="font-12 tertiary mt2">
          {getDisplayBirthDate(litter)}. Ready {litter.ready_date_month_year}
        </p>
      </div>
      <Button
        data-test-id={`litter-${litter.id}`}
        className="font-16"
        size="default"
        variant="outline"
        onClick={() => {
          onLitterClick(litter.id);
        }}
      >
        View litter
      </Button>
    </div>
  );
}

LitterCard.propTypes = {
  litter: PropTypes.shape(BreederProfileLitterPropTypes).isRequired,
  onLitterClick: PropTypes.func.isRequired
};

export default function BreederProfileLittersSectionContainer({
  className,
  mobileLayoutType
}) {
  const dispatch = useDispatch();
  const { breederProfileId, isSearchEngineReferral } = useSelector(
    ({ server }) => server
  );
  const { activeTabKey } = useBreederProfileTabs();

  const [isExpanded, toggleIsExpanded] = useReducer(
    expanded => !expanded,
    false
  );

  const {
    isFetching,
    value: { data: litters }
  } = useResourceQuery(
    littersResourceDefinition(breederProfileId, {
      exclude_adult: true
    })
  );

  if (isFetching) {
    return <BreederProfileLittersSkeleton className={className} />;
  }

  if (litters.length === 0) {
    return null;
  }

  const hasPuppies = litters.some(litter => litter.puppies.length > 0);

  const hasMoreLitters = litters.length > MAX_LITTERS_TO_SHOW;
  const visibleLitters = litters.slice(
    0,
    isExpanded ? litters.length : MAX_LITTERS_TO_SHOW
  );

  function onLitterClick(litterId) {
    dispatch(pushParams({ [LITTER_ID]: litterId }, { hash: true }));
  }

  const isMobileStackedLayout =
    mobileLayoutType === MOBILE_LAYOUT_TYPES.STACKED;

  const MobileLayoutComponent = isMobileStackedLayout
    ? 'div'
    : HorizontalSlider;

  const showBorder =
    (hasPuppies || isSearchEngineReferral) && activeTabKey !== TAB_KEYS.DOGS;

  return (
    <section className={classnames('BreederProfileLittersSection', className)}>
      <div className="container-xxl">
        <div
          className={classnames('pv12 pv20-md b--gray-300', {
            bt: showBorder
          })}
        >
          <h4 className="mb6 mb10-md font-24 md:font-32 lh-title">
            Newborn & upcoming litters
          </h4>

          {/* mobile */}
          <div className="db dn-ns">
            <MobileLayoutComponent
              className={classnames(
                isMobileStackedLayout
                  ? 'BreederProfileLittersList flex flex-column gap-8'
                  : 'BreederProfileLittersSlider'
              )}
              {...(!isMobileStackedLayout && {
                containerClassName:
                  'BreederProfileLittersSlider__container flex flex-nowrap w-100 gap-8'
              })}
            >
              {litters.map(litter => (
                <div key={litter.id} className="flex ws-normal">
                  <LitterCard litter={litter} onLitterClick={onLitterClick} />
                </div>
              ))}
            </MobileLayoutComponent>
          </div>

          {/* desktop */}
          <div className="dn flex-ns flex-column gap-8">
            {visibleLitters.map(litter => (
              <div
                key={litter.id}
                className="BreederProfileLitterSectionLineItem w-100 bg-white items-center gap-8 br4 ba b--subtle-gray overflow-hidden ph5 pv5"
              >
                <div className="flex flex-auto items-center mw-100">
                  <LitterParentsVennImages
                    litterParents={litter.parents}
                    vennImageProps={SMART_IMAGE_PROPS}
                  />
                  <p className="font-serif font-20 ml4 truncate">
                    {getLitterStatus(litter)}
                  </p>
                </div>

                <p className="font-16 tertiary">
                  {getDisplayBirthDate(litter)}
                </p>
                <p className="font-16 tertiary">
                  Ready {litter.ready_date_month_year}
                </p>

                <Button
                  data-test-id={`litter-${litter.id}`}
                  size="small"
                  variant="outline"
                  onClick={() => {
                    onLitterClick(litter.id);
                  }}
                >
                  View litter
                </Button>
              </div>
            ))}
          </div>

          {hasMoreLitters && (
            <button
              onClick={toggleIsExpanded}
              className="dn dib-ns underline stone-700 font-16 mt6"
              type="button"
            >
              {isExpanded ? 'Show less' : 'Show more'}
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

BreederProfileLittersSectionContainer.propTypes = {
  className: PropTypes.string,
  mobileLayoutType: PropTypes.oneOf(Object.values(MOBILE_LAYOUT_TYPES))
};

BreederProfileLittersSectionContainer.defaultProps = {
  className: 'bg-subtle-light-gray',
  mobileLayoutType: MOBILE_LAYOUT_TYPES.CAROUSEL
};
